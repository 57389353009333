import router from "./router";
import store from "./store";

// 导航守卫，用于在每次路由跳转时进行判断
router.beforeEach((to, from, next) => {
  const accessToken = sessionStorage.getItem("accessToken");
  const refreshToken = sessionStorage.getItem("refreshToken");

  // 判断是否存在 accessToken 和 refreshToken
  if (accessToken && refreshToken) {
    // 如果存在令牌，访问登录页或根路径时重定向到聊天页
    if (to.path === "/" || to.path === "/login") {
      if (Object.keys(to.query).includes("type")) store.commit("setQueryParams", to.query);
      next("/home/chat");
    } else {
      next(); // 否则允许继续访问
    }
  } else {
    next(); // 如果没有令牌，直接继续导航
  }
});
