export function extractUserLists(departments) {
  let userLists = [];
  departments.forEach((dept) => {
    // 添加当前部门的userList
    if (dept.userList && dept.userList.length > 0) {
      userLists = userLists.concat(dept.userList);
    }

    // 如果当前部门有子部门，则递归提取子部门的userList
    if (dept.children && dept.children.length > 0) {
      userLists = userLists.concat(extractUserLists(dept.children));
    }
  });

  return userLists;
}
