import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../view/Login'
import Register from '../view/Register'
import Home from '../view/Home'

// 安装路由插件
Vue.use(VueRouter);

// 配置并导出路由
const router = new VueRouter({
  routes: [
    {
      path: "/",
      redirect: "/login" // 默认跳转到登录页
    },
    {
      name: "Login",
      path: '/login',
      component: Login // 登录页组件
    },
    {
      name: "Register",
      path: '/register',
      component: Register // 注册页组件
    },
    {
      name: "Home",
      path: '/home',
      component: Home, // 首页组件
      children: [
        {
          name: "Chat",
          path: "/home/chat",
          component: () => import("../view/Chat"), // 聊天页组件
        },
        {
          name: "Friend",
          path: "/home/friend",
          component: () => import("../view/Friend"), // 朋友页组件
        },
        {
          name: "Group",
          path: "/home/group",
          component: () => import("../view/Group"), // 群组页组件
        }
      ]
    }
  ]
});

export default router;
