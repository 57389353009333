/**
 * 从URL中获取指定的查询参数
 * @param {string} variable 要获取的参数名称
 * @returns {string | null} 返回参数值，如果参数不存在则返回null
 */
function getQueryVariable(variable) {
    const url = window.location.href; // 获取当前页面的URL
    const hashIndex = url.indexOf('#'); // 查找hash符号的位置
    if (hashIndex === -1) return null; // 如果URL中没有hash符号，返回null

    const hash = url.substring(hashIndex + 1); // 获取hash后的字符串
    const urlParams = new URLSearchParams(hash.split('?')[1]); // 分割查询字符串并创建URLSearchParams对象

    return urlParams.get(variable); // 返回指定参数的值
}

/**
 * 设置Token到sessionStorage
 */
function setTokens() {
    const accessToken = getQueryVariable('accessToken'); // 获取accessToken
    const refreshToken = getQueryVariable('refreshToken'); // 获取refreshToken

    // 如果accessToken和refreshToken都存在，保存到sessionStorage
    if (accessToken && refreshToken) {
        sessionStorage.setItem("accessToken", accessToken);
        sessionStorage.setItem("refreshToken", refreshToken);
    }
}

// 调用函数以设置Tokens
setTokens();
